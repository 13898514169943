<template>
  <v-container fluid class="down-top-padding pt-0">
    <v-row>
      <v-col cols="12">
        <div>
          <v-data-table
            :loading="loading"
            item-key="id"
            class="elevation-0 pb-4 pt-4 members_list_table"
            :headers="headers"
            :items="transactionHistory"
            :expanded.sync="expanded"
            :show-expand="false"
            :page.sync="page"
            :disable-sort="true"
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
            <template v-slot:top class="px-3">
              <v-toolbar flat class="mb-4 px-3">
                <h2 class="transactionhead">
                  {{ $t("financial.transactionHistory") }}
                </h2>
                <v-spacer></v-spacer>
                <h3 class="transactionhead">
                  {{
                    transactionHistory[0]
                      ? transactionHistory[0].user
                        ? transactionHistory[0].user.name
                        : ""
                      : ""
                  }}
                </h3>
              </v-toolbar>
            </template>
            <template v-slot:item.status="{ item }">
              <span>{{
                item.status === 0
                  ? "Pending"
                  : item.status === 1
                  ? "Success"
                  : "Card Error"
              }}</span>
            </template>
            <template v-slot:item.paid_at="{ item }">
              <span>{{ item.paid_at | datefilter }}</span>
            </template>
            <template v-slot:item.amount="{ item }">
              <span>${{ item.amount }}</span>
            </template>
            <template v-slot:item.ends_at="{ item }">
              <span>{{ item.ends_at | datefilter }}</span>
            </template>
            <template v-slot:item.receipt_url="{ item }">
              <span v-if="item.receipt_url"
                ><a target="_blank" :href="item.receipt_url"
                  >View Receipt</a
                ></span
              >
              <span v-if="!item.receipt_url">{{ item.failed_reason }}</span>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize(1)"> Reset </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-if="transactionHistory.length > 0"
              v-model="meta.current_page"
              :length="meta.last_page"
              @input="initialize"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VIEW_USER, MANAGE_USER } from "@/constants/permission";
import { showSnackbar } from "../eventbus/action.js";
export default {
  name: "TransactionHistory",
  components: {},
  data() {
    return {
      manageUser: false,
      viewUser: false,
      expanded: [],
      page: 1,
      id: null,
      pageCount: 2,
      itemsPerPage: 50,
      headers: [
        {
          text: this.$t("financial.paidAt"),
          align: "start",
          value: "paid_at",
          width: "15%",
        },
        {
          text: this.$t("financial.amount"),
          value: "amount",
          width: "10%",
        },
        {
          text: this.$t("financial.description"),
          value: "description",
          width: "15%",
        },
        {
          text: this.$t("financial.recieptUrl"),
          value: "receipt_url",
          width: "15%",
        },
        {
          text: this.$t("financial.paymentMethod"),
          value: "payment_method.name",
          width: "15%",
        },
        {
          text: this.$t("financial.paymentEndAt"),
          value: "ends_at",
          width: "20%",
        },
        {
          text: this.$t("financial.status"),
          value: "status",
          width: "10%",
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      transactionHistory: "financialDashboard/getTransactionHistory",
      meta: "financialDashboard/getTransactionMeta",
      user: "user/getUser",
    }),
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = Number(this.$route.params.id);
      this.initialize(1);
    }
  },

  methods: {
    ...mapActions({
      getTransactionHistory: "financialDashboard/getTransactionHistory",
    }),

    async initialize(page) {
      this.page = page;
      const userPermission = this.user.permission.map((val) => val.name);
      this.manageUser = userPermission.includes(MANAGE_USER);
      this.viewUser = userPermission.includes(VIEW_USER);
      this.loading = true;
      try {
        await this.getTransactionHistory({
          page: this.page,
          id: this.id,
        });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
